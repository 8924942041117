

#root {
  display: flex;
}

.app-splash {
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;
  padding: 2rem;
  text-align: center;

}

.start {
  .banner {
    display: none;
  }
}


.app {

  padding-bottom: 4rem;


  .bottom-bar {

    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    padding: 1rem;
    height: 4rem;

    font-weight: bold;
    font-size: .5rem;
    .category {
      margin: 0 1rem;
    }
    >div {
      width: initial;

    }

    svg {
      max-height: 2rem;
      max-width: 3rem;
    }

  }
}









body, .btn {
  font-size: 1rem;
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
}
h3 {
  font-size: 1.2rem;
}
h4 {
  font-size: 1.1rem;
}
h5 {
  font-size: 1rem;
}
