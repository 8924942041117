// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 300;
//   src: url('./fonts/Source_Sans_Pro/SourceSansPro-Light.ttf');
// }
//
// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 400;
//   src: url('./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
// }
//
// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 600;
//   src: url('./fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf');
// }
//
// @font-face {
//   font-family: 'Source Sans Pro';
//   font-style: normal;
//   font-weight: 700;
//   src: url('./fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf');
// }


@font-face {
  font-family: 'Brown Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/BrownPro/BrownPro-Regular.ttf');
}

@font-face {
  font-family: 'Brown Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/BrownPro/BrownPro-Bold.ttf');
}
