


$primary: #931a2f;
$dimmedBackground: #f3f3f3;
$selection: #0096ec;



@import "~bootstrap/scss/bootstrap";



html, body, #root, .app {
  height: 100%;
  width: 100%;
}



body, .btn {
  font-size: 2.5vmin;
}

h1 {
  font-size: 5vmin;
}
h2 {
  font-size: 4vmin;
}
h3 {
  font-size: 3vmin;
}
h4 {
  font-size: 2vmin;
}
h5 {
  font-size: 1vmin;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Brown Pro', 'Source Sans Pro', sans-serif;
}




.swiper-button-next, .swiper-button-prev {
  &:after{
    color: #000;
    font-size: 3vmin !important;

  }
}
.swiper-pagination {
  bottom: -.5vmin !important;
  .swiper-pagination-bullet-active{
    background-color: #000;

  }
}



.app {
  display: flex;
  flex-direction: column;
  background-color: $dimmedBackground;

  .bottom-bar {
    flex:0;
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 2vmin;

    background-color: #931a2f;
    color: #ffffff;
    min-height: 10vmin;

    font-weight: bold;
    font-size: 2vmin;

    >div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20vmin;
      opacity: .7;
      &.active {
        opacity: 1;
      }
    }


    .home {
      margin-right: auto;

    }
    .vehicle {
      margin-left: auto;
      &.hide {
        visibility: hidden;
      }
    }

    svg {
      max-height: 5vmin;
      max-width: 8vmin;
    }
    svg path {
      fill: #ffffff;
    }
  }

}


.current-car {
  // z-index: 999;
  // position: fixed;
  // bottom: 12vmin;
  background: #a6ca9a;
  align-items: center;
  // right: 1vmin;
  display: flex;
  padding: 1vmin 4vmin ;
  box-shadow: 0 0 3vmin rgba(0,0,0,.2);
  h3 {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 2vmin !important;
  }
  .vehicle {
    display: flex;
    >div {
      margin-left: 1rem;

    }
  }



  .regno {

    .country {
      background-color: #0b65c1;
      padding: 0.5vmin;
      .inner {

        width: 2vmin;
        height: 100%;

        background-image: url('../images/vehicle/license_plate_0003_se.png');
        background-size: contain;
        background-repeat: no-repeat;

      }

    }


    .text {
      padding: 0 .7vmin;
    }

    border: solid 1px #ccc;
    background: #ffff;
    display: flex;
    overflow: hidden;
    border-radius: 1vmin;

  }

  .reset-vehicle {
    svg {
      width: 4vmin;
      margin-right: 1vmin;

    }
  }

}


.products {

  padding: 2vmin;
  flex: 1;

  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;


  .products-list {
    margin: 2vmin -2vmin;
    margin-top:1vmin;
    background-color: #ffffff;
    border: solid 1px #ccc;


    .swiper-container {
      padding: 2vmin;
      // overflow: visible;
    }

    .product {

      .image {
        // padding: 1vmin;
        height: 15vmin;
        img {
          height: 15vmin;
        }
        svg {
          height: 15vmin;
          path {
            fill: #999;
          }
        }
      }

      border-radius: 1vmin;
      border: solid 2px #ccc;
      // padding: 1vmin;
      background: #ffffff;

      display: flex;
      flex-direction: column;
      align-items: center;
      &.active {
        border-color: $selection;
        box-shadow: 0 0 1vmin $selection;
      }
      .title {

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-top: 1vmin;

      }

    }


  }


  .intro {
    .btn {
      margin-left: 1vmin;
    }
  }
  .product-status {
    flex: 1;
    font-size: 3vmin;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
  }


  .product-details {

    flex: 1;

    overflow: scroll;

    .info {
    }
    // Current car popup
    // padding-right: 25vmin;
    .video-button {
      img {width: 3vmin;}
    }

    .important-vehicle-info {
      background-color: #cae7ff;
      img {
        width: 3vmin;
      }
      padding: 1vmin;
      border: solid 1px #ccc;
      margin-bottom: 1vmin;

    }
    .image {
      margin-bottom: 1vmin;
      background: #ffffff;
      border: solid 1px #ccc;
      padding: 2vmin;
      border-radius: 2vmin;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 40vmin;
      margin-right: 5vmin;
      overflow: hidden;

      img, svg {
        width: 100%;
        height: auto;
      }
    }
  }
}




.product-filters{
  flex: 1;
  overflow: scroll;
  position: relative;
  padding: 2vmin;
  display: flex;
  flex-direction: column;

  .filter-buttons {

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn {
      display: block;
      margin-top: 1vmin;
      font-size: 3vmin;

    }

  }
}

.subcategory {
  flex: 1;
  overflow: scroll;
  position: relative;
  padding: 2vmin;

  .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10vmin;
    .btn {
      font-size: 3vmin;
      margin: 1vmin;
    }
  }


  .header {
    z-index: 999;
    position: relative;
  }

  .car-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;

  }
  .car-container {
    position: relative;

    .car {
      position: absolute;
      z-index: 1;
    }
    .car-lines {
      z-index: 10;
      position: relative;

      .line {
        border-bottom: solid 1px #000;
        transform-origin: 0px 0px;
        position: absolute;
        // box-shadow: 0px 1px 5px #000;
        opacity: .2;

      }
    }
    .car-buttons {
      z-index: 11;
      position: relative;

      .car-button {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 8vmin;
        margin-top: -4vmin;
        margin-left: -4vmin;

        white-space: nowrap;

        // font-weight: bold;
        .icon {
          background: #333;

          &:active {
            background: #666;
          }
          display: flex;
          width: 8vmin;
          height: 8vmin;
          border-radius: 10vmin;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          img {
            width: 8vmin;
            height: auto;
          }

        }
      }
    }
    .car {
      z-index: 1;
      img {
        position: absolute;
        top:0;
        width: 100%;

      }
    }
  }
}


.find-vehicle {
  flex:1;
  position: relative;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 2vmin;

  overflow: scroll;



  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 2vmin;

    .btn {
      margin-left: 3vmin;
      // height: 100%;
      font-weight: bold;
      font-size: 3vmin;
    }


    .input {

      display: flex;
      flex-direction: row;

      border: solid 2px #ccc;
      border-radius: 1vmin;
      overflow: hidden;
      background: #fff;

      .country {
        background-color: #0b65c1;
        padding: 2vmin;

        .inner {
          width: 7vmin;
          height: 100%;

          background-image: url('../images/vehicle/license_plate_0003_se.png');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }


      input {
        // --webkit-user-select: auto;
        padding: .5rem;
        border: none;
        text-transform: uppercase;
        font-size: 2rem;
      }
      .items {
        padding: 2vmin;
        display: flex;
        flex-direction: row;
        overflow: auto;

      }
      .item {



        margin: 1vmin;
        font-weight: bold;
        font-size: 4vmin;
        width: 10vmin;
        height: 10vmin;
        padding: 1vmin;
        border-radius: 2vmin;
        border: solid 1px #ccc;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          background-color: #eee;
        }
      }
    }

  }



  .virtual-keyboard ul {


    list-style: none;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    width:100%;
    margin:0;
    padding:0;

    li {
      background-color: #ffffff;
      margin: 1vmin;
      font-weight: bold;
      font-size: 4vmin;
      width: 10vmin;
      height: 10vmin;
      padding: 1vmin;
      border-radius: 2vmin;
      border: solid 1px #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        background-color: #ddd;
      }

    }
  }
}


.spinner-container {
  position: absolute;
  z-index: 99;
  bottom: 0px;
  top:0px;
  right:0px;
  left:0px;
  background-color: rgba(255,255,255,.5);
  display: flex;
  align-items: center;
  justify-content: center;
}



.start {
  flex: 1;
  display: flex;
  flex-direction: column;


  .banner {
    // min-height: 50%;

    background: #000000;

    flex: 1;
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    position: relative;

    .tint {
      position: absolute;
      top:0;
      left:0;
      right: 0;
      bottom: 0;
      opacity: .2;
      background-color: #000000;
    }

    h1 {
      z-index: 2;
      font-size: 5vmin;
      font-weight: bold;

    }
  }


  .main {

    display: flex;
    flex-direction: column;
    padding: 2vmin;
    // flex:1;
    background: $dimmedBackground;


    h2 {
      font-size: 4vmin;
    }

    .copy {
      position: absolute;
      bottom: 1vmin;
      right: 1vmin;
      font-size: 2vmin;
    }


    .go-to-app {
      height: 25vmin;
      width: 25vmin;

      position: relative;

      white-space: nowrap;
      margin-top:-8vmin;

      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 2.75vmin;
        // margin-bottom: 0;
      }


      svg.arrow {
        position: absolute;
        top:0;
        right:-8vmin;
        path {
          fill: $primary;
        }
        width: 10vmin;
      }

      .qr-code {
        background: #ffffff;
        padding: 3vmin;
        align-items: center;
        display: flex;
        canvas {
          width: 20vmin;
        }

      }
    }

    .buttons {
      display: flex;
      flex:1;
      padding: 5vmin;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;


      .category-item {
        // border-radius: 1vmin;
        color: #ffffff;
        &:active {
          opacity: .8;

        }

        box-shadow: 0 0 2vmin rgba(0,0,0,.2);

        height: 25vmin;
        width: 25vmin;

        padding: 4vmin;
        padding-bottom: 7vmin;
        // margin: 1vmin;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;

        svg path {
          width: 100%;
          fill: #ffffff;
        }

        h3 {
          position: absolute;
          // margin-top: auto;
          bottom: 1vmin;
          font-size: 3vmin;


        }
      }
    }
  }


}




.mode-app {
  .start {
    .banner {
      background: url('https://content.st1.fi/sites/default/files/2018-09/iStock-888084372.jpg');
      background-size: cover;
      background-position: center;
    }
  }
}
